import { Component } from "react";
import { db } from "../../firebase";

import { AuthContext } from "../../shared/Auth";
import * as moment from "moment";

import { storage, functions } from "../../firebase";
import { Link, Route } from "react-router-dom";

import JobDetailsInfo from "./JobDetailsInfo";
import JobCrew from "./JobCrew";
import JobEquipment from "./JobEquipment";
import JobChecklist from "./JobChecklist";
import JobDocuments from "./JobDocuments";
import Bores from "./Bores/Bores";
import JobCrews from "./JobCrews";

import Loader from "../../components/Layout/Loader/Loader";
import { validateField } from "../../shared/Validation";

import {
  getJob,
  updateJob,
  uploadImage,
  getClients,
  getBoreImagesForJob,
  getCrews,
  getJobPhotos,
  getContacts,
  getClient,
  getCrewAppointments,
} from "../../shared/Api";

import {
  getReservationsForAJob,
  getCustomChecklists,
  getBores,
  getCrewSchedulesForJob,
  getDocuments,
} from "../../shared/Api";
import {
  CheckList,
  JobBores,
  Folder,
  JobsIos,
  CrewmemberIos,
  EquipmentIos,
  CrewsIos,
  LocateTicket,
} from "../../components/Icons/Svg";
import { getMessages } from "../../components/Messages/GetMessages";
import { normalizeInput } from "../../shared/FormHelper";
import InfoModal from "../../components/Modals/InfoModal";
import TaskManagement from "./TaskManagement/TaskManagement";
import { getImageUrlFromFirebase, showToast, toFirebaseDate } from "../../shared/Util";
import LocateTicketComponent from "../LocateTicket/LocateTicketComponent";
import { getLocateTicketFiles, getLocateTickets } from "../../shared/api/LocateTicketsApi";
import { buildLocateTicketFileStructure } from "../../shared/data/LocateTickets";
import {  getTasksWithTimeLogs } from "../../shared/api/TaskManagerApi";
import { TimeLogEntity } from "../../shared/data/taskmanagement/TimeLog";
import { updateJobReservations } from "../../shared/api/JobApi";

class JobDetails extends Component {
  static contextType = AuthContext;
  user = this.context.currentUser.userProfile;
  company = this.context.currentUser.company;
  canEdit = this.user.permissions !== "crewmember";

  state = {
    job: null,
    deleteJobModalActive: false,
    imageViewerActive: false,
    addedCrewmembers: [],
    otherCrewmembers: [],
    addedEquipment: [],
    otherEquipment: [],
    checkinList: [],
    checkoutList: [],
    boreImages: [],
    images: null,
    photosUploading: false,
    selectedImageIndex: 0,
    bores: [],
    tabActive: "details",
    clients: [],
    documents: null,
    changingClient: false,
    boresLoading: false,
    checklistsLoading: false,
    equipmentListLoading: false,
    crewListLoading: false,
    selectedImageFolder: null,
    foldersPath: [],
    addedCrews: null,
    otherCrews: null,
    jobPhotos: null,
    zipChecked: false,
    preparingZipData: false,
    zipExists: false,
    zipExistsPoolingCount: 0,
    delay: 5000,
    showTutorialModal: false,
    clientDetailsForm: {
      fields: [
        {
          name: "companyName",
          value: "",
          defaultValue: "",
          type: "text",
          label: "Company Name",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: null,
          required: true,
          validators: [
            {
              type: "required",
              message: getMessages("company_required"),
            },
          ],
        },
        {
          name: "phoneNumber",
          value: "",
          defaultValue: "",
          type: "text",
          subtype: "phone",
          label: "Phone number",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: null,
          required: true,
          validators: [
            {
              type: "required",
              message: getMessages("phone_required"),
            },
          ],
        },
      ],
      isValid: false,
      submitError: false,
      submitErrorMessage: "",
      loading: false,
    },
    jobDetailsForm: {
      fields: [
        {
          name: "title",
          value: "",
          defaultValue: "",
          type: "text",
          label: "Job name",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: getMessages("job_name"),
          required: true,
          validators: [
            {
              type: "required",
              message: getMessages("job_name_required"),
            },
          ],
        },
        {
          name: "jobID",
          value: "",
          defaultValue: "",
          type: "text",
          label: "Job ID",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: getMessages("job_id"),
          required: true,
          validators: [
            {
              type: "required",
              message: getMessages("job_id_required"),
            },
          ],
        },
        {
          id: "job_details_date",
          name: "dates",
          value: "",
          startDate: {
            name: "startDate",
            value: null,
          },
          endDate: {
            name: "endDate",
            value: null,
          },
          defaultStartDate: "",
          defaultEndDate: "",
          defaultValue: "",
          type: "daterangepicker",
          label: "Dates",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: getMessages("job_startend"),
          required: true,
        },
        {
          name: "status",
          value: "",
          defaultValue: "",
          type: "radiobuttons",
          label: "Job Type",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: getMessages("equappment_jobtype"),
          required: true,
          options: [
            {
              label: "Active Job",
              value: "published",
            },
            {
              label: "Completed Job",
              value: "completed",
            },
            {
              label: "Bid",
              value: "draft",
            },
          ],
        },
        {
          name: "description",
          value: "",
          defaultValue: "",
          type: "textarea",
          label: "Description (optional)",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: getMessages("job_description"),
          required: false,
          rows: 4,
        },
        {
          name: "totalFootage",
          value: "",
          defaultValue: "",
          type: "text",
          label: "Total Footage (optional)",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: null,
          required: false,
        },
      ],
      isValid: false,
      submitError: false,
      submitErrorMessage: "",
      loading: false,
    },
  };

  componentDidMount = async () => {
    const updateJobIdBack = this.context.updateJobIdBack;

    updateJobIdBack(this.props.match.params.id);

    await this.loadData();

    if (this.state.job.status === "completed") {
      this.checkZipCreatedDate();
    }
  };

  // componentDidUpdate = async (prevProps, prevState) => {
  //   if (prevState.delay !== this.state.delay) {
  //     // clearInterval(this.interval);
  //     // this.interval = setInterval(this.tick, this.state.delay);
  //   }
  // };

  componentWillUnmount() {
    window.$('[data-toggle="tooltip"]').tooltip("hide");
  }

  resetJobDetailsForm = () => {
    let updatedForm = { ...this.state.jobDetailsForm };
    updatedForm.isValid = false;
    updatedForm.submitError = false;
    updatedForm.submitErrorMessage = "";
    updatedForm.loading = false;
    updatedForm.fields.forEach((x) => {
      x.value = x.defaultValue;
      x.isTouched = false;
      x.isValid = true;
    });

    this.setState({ jobDetailsForm: updatedForm });
  };

  resetClientDetailsForm = () => {
    let updatedForm = { ...this.state.clientDetailsForm };
    updatedForm.isValid = false;
    updatedForm.submitError = false;
    updatedForm.submitErrorMessage = "";
    updatedForm.loading = false;
    updatedForm.fields.forEach((x) => {
      x.value = x.defaultValue;
      x.isTouched = false;
      x.isValid = true;
    });

    this.setState({ clientDetailsForm: updatedForm });
  };

  loadData = async () => {
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const job = await getJob(inventoryId, id);

    let data = job.data;
    data.id = job.data.id;

    if (job.data.clientID) {
      const client = await getClient(inventoryId, job.data.clientID);

      data.client = client.data;
      const contactsResponse = await getContacts(inventoryId, data.clientID);
      data.client.contacts = contactsResponse.data;

      if (data.client.firstName || data.client.lastName) {
        data.client.contacts.unshift({
          id: data.clientID,
          firstName: data.client.firstName,
          lastName: data.client.lastName,
          position: "",
          email: "",
          phoneNumber: "",
        });
      }
    }

    let jobDetailsForm = { ...this.state.jobDetailsForm };
    await Promise.all(
      jobDetailsForm.fields.map(async (x) => {
        if (x.name === "dates") {
          x.startDate.value = moment(data?.startDate?.toDate());
          x.endDate.value = moment(data?.endDate?.toDate());
          x.defaultStartDate = moment(data.startDate?.toDate());
          x.defaultEndDate = moment(data?.endDate?.toDate());
        } else {
          let value = data[x.name];
          x.value = value;
          x.defaultValue = value;
        }
      })
    );

    if (data.clientID) {
      let clientDetailsForm = { ...this.state.clientDetailsForm };
      await Promise.all(
        clientDetailsForm.fields.map(async (x) => {
          let value = data.client[x.name];
          if (x.subtype === "phone") value = normalizeInput(value, "");

          x.value = value;
          x.defaultValue = value;
        })
      );
    }

    this.setState({ job: data, jobDetailsForm: jobDetailsForm });
    this.loadCrew(id, inventoryId, currentUser.userProfile.companyID);
    this.loadEquipment(id, inventoryId, currentUser.userProfile.companyID);
    this.loadJobPhotos(id, inventoryId, currentUser.userProfile.companyID);

    this.loadCheckLists(data, inventoryId);
    this.loadBores(data.id, inventoryId);
    this.loadClients();
    this.loadDocuments(data.id, inventoryId, currentUser.userProfile.companyID);
    this.loadCrews(data.id, inventoryId);
  };

  buildLocateTicketsFolders = async (jobId) => {
    const inventoryId = this.context.currentUser.company.inventoryID;
    const locateTickets = await getLocateTickets(inventoryId, jobId);

    const locateTicketFolder = [];
    await Promise.all(
      locateTickets.data?.map(async (locateTicket) => {
        try {
          const result = await getLocateTicketFiles(locateTicket.id);
          const folder = await buildLocateTicketFileStructure(
            locateTicket,
            result.data ?? []
          );
          locateTicketFolder.push(folder);
        } catch (error) {}
      })
    );

    const locateTicketsFiles = locateTicketFolder
      ?.filter((locateTicket) => locateTicket.children?.length > 0)
      ?.map((locateTicket) => {
        const images = locateTicket.children?.filter((child)=>(child.fileType=="photo")).map((child) => ({
          name: child.name,
          fileLink: child.url,
          type: child.fileType,
          createdAt: child.createdAt,
        }));

        const folder = {
          id: locateTicket.id,
          display: locateTicket.name,
          name: locateTicket.name,
          images: images,
          isBore: true,
          type: "folder",
          viewerImages:
            images
              ?.filter((image) => image.type == "photo")
              ?.map((image) => {
                return { src: image.fileLink };
              }) ?? [],
        };

        return folder;
      });

    return {
      id: Math.random().toString(),
      display: "Locate Tickets",
      name: "Locate Tickets",
      images: locateTicketsFiles,
      level: 1,
      type: "folder",
      isBore:true
    };
  };

  buildLocateTicketsFileFolders = async (jobId) => {
    const inventoryId = this.context.currentUser.company.inventoryID;
    const locateTickets = await getLocateTickets(inventoryId, jobId);
    const rootFolderId = Math.random().toString()

    const locateTicketFolder = [];
    await Promise.all(
      locateTickets.data?.map(async (locateTicket) => {
        try {
          const result = await getLocateTicketFiles(locateTicket.id);
          const folder = await buildLocateTicketFileStructure(
            locateTicket,
            result.data ?? []
          );
          locateTicketFolder.push({
            categoryId: rootFolderId,
            id: folder.id,
            children: folder.children,
            display: folder.name,
            name: folder.name,
            subfolders: [],
            level: 2,
            uploadedAt: folder.createdAt,
            type: "folder",
            readOnly:true
          });
        } catch (error) {}
      })
    );

    const locateTicketsFiles = locateTicketFolder
      ?.filter((locateTicket) => locateTicket.children?.length > 0)
      ?.map((locateTicket) => {
        const files = locateTicket.children?.filter((child)=>(child.fileType=="document")).map((child) => ({
          categoryId: locateTicket.id,
          name: child.name,
          filename: child.name,
          description: child.notes,
          fileLink: child.url,
          fileType:"document",
          type: child.fileType,
          readOnly:true,
          subfolders:[],
          uploadedAt: child.createdAt,
        }));

        return {...locateTicket, files:files};
      });

    return {
      categoryId: null,
      id: rootFolderId,
      display: "Locate Tickets",
      name: "Locate Tickets",
      subfolders: locateTicketsFiles.filter((file)=>(file.files?.length>0 || file.subfolders?.length>0)),
      level: 1,
      type: "folder",
      readOnly:true
    };
  };

  buildTasksFolders = async (jobId, companyId) => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const rootFolderId = Math.random().toString();

    const tasksWithTimeLogs = await getTasksWithTimeLogs(inventoryId, jobId);

    const tasksWithPhotos = tasksWithTimeLogs?.data?.filter(
      (task) =>
        task.timeLogs.filter(
          (timeLog) => timeLog.photos || timeLog.imageTimeStamp
        ).length > 0
    );

    const folderStructure = await Promise.all(
      tasksWithPhotos.map(async (task) => {
        const filteredTimeLogs = task.timeLogs.filter((timeLog) => {
          return timeLog.photos?.length > 0 || timeLog.imageTimeStamp;
        }).flatMap((timeLog)=>timeLog);
        
        const timeLogImages =  (await Promise.all(
          filteredTimeLogs.map(async (timeLog) => {
            return await Promise.all(
              TimeLogEntity.getAllImagesUrls(timeLog).map(async (image) => {
                const imageUrl = await getImageUrlFromFirebase(
                  companyId,
                  image.src
                );

                return {
                  name: timeLog.notes,
                  fileLink: imageUrl,
                  type: "photo",
                  createdAt: image.value,
                };
              })
            );

          })
        )).flatMap((image)=>image);

        const taskFolder = {
          id: task.task.id,
          categoryID: rootFolderId,
          display: task.task.title,
          name: task.task.title,
          images: timeLogImages,
          isBore: true,
          type: "folder",
          viewerImages:
          timeLogImages
              ?.filter((image) => image.type == "photo")
              ?.map((image) => {
                return { src: image.fileLink };
              }) ?? [],
        };

        return taskFolder

      })
    );

    return {
      id: rootFolderId,
      display: "Tasks",
      name: "Tasks",
      images: folderStructure,
      level: 1,
      type: "folder",
      isBore:true
    };
  };

  buildBoreFolder = async (inventoryId, companyId, jobId) => {
    const rootFolderId = Math.random().toString();
    const result = await getBoreImagesForJob(inventoryId, companyId, jobId)

    const boreImages = result.map((bore)=>{
      return {
        id: bore.id,
          categoryID: rootFolderId,
          display: bore.name,
          name: bore.name,
          images: bore.images,
          isBore: true,
          type: "folder",
          viewerImages:
          bore.images
              ?.filter((image) => image.type == "photo")
              ?.map((image) => {
                return { src: image.fileLink };
              }) ?? [],
      }
    })

    return {
      id: rootFolderId,
      display: "Bores",
      name: "Bores",
      images: boreImages,
      level: 1,
      type: "folder",
      isBore:true
    };
  }

  loadJobPhotos = async (jobId, inventoryId, companyId) => {

    const [photos, boreImages, locateTicketFiles, tasksFiles] = await Promise.all([
      getJobPhotos(inventoryId, jobId, companyId),
      this.buildBoreFolder(inventoryId, companyId, jobId),
      this.buildLocateTicketsFolders(jobId),
      this.buildTasksFolders(jobId, companyId)
  ]);
  

    const viewerImages = photos.data
      .filter((x) => x.type === "photo")
      .map((x) => {
        return { src: x.fileLink, alt: x.name };
      });

    photos.data
      .filter((x) => x.type === "folder")
      .forEach((element) => {
        element.viewerImages = element.images.map((x) => {
          return { src: x.fileLink, alt: x.name };
        });
      });

    let docs = photos.data.filter((x) => x.type === "folder");
    if (locateTicketFiles) docs = docs.concat(locateTicketFiles);
    docs = docs.concat(tasksFiles);
    docs = docs.concat(boreImages);
    docs = docs.concat(photos.data.filter((x) => x.type === "photo"));

    const selectedFolder = docs.find((folder)=>folder.id==this.state?.selectedImageFolder?.id)
    
    this.setState({ jobPhotos: docs, viewerImages: viewerImages, selectedImageFolder:selectedFolder });
  };

  loadClients = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const jobClients = this.state?.job?.clients?.map((client) => client);
    jobClients?.push(this.state.job.clientID);

    const clientsResponse = await getClients(inventoryId);
    const clients = clientsResponse.data;

    clients.forEach(async (x) => {
      const getContactsResult = await getContacts(inventoryId, x.id);
      const contactList = getContactsResult.data;
      x.contacts = contactList;
    });
    this.setState({ clients: clients });
  };

  updateJobClientContacts = (list) => {
    const job = this.state.job;
    job.client.contacts = list;
    this.setState({ job: job });
  };

  loadDocuments = async (jobId, inventoryId, companyId) => {
    
    const result = await getDocuments(inventoryId, jobId, companyId)
    const locateTicketFiles = await this.buildLocateTicketsFileFolders(jobId)
    this.setState({ documents: [locateTicketFiles, ...result.data] });
  };

  loadCrew = async (jobId, inventoryId, companyId) => {
    this.setState({ crewListLoading: true });
    const companyCrew = await db
      .collection("inventories")
      .doc(inventoryId)
      .collection("crew")
      .get();

    const data = await Promise.all(
      companyCrew.docs.map(async (doc) => {
        let dat = doc.data();
        dat.id = doc.id;

        if (dat.hasProfileImage && dat.imageTimeStamp) {
          try {
            const storageRef = storage.ref(
              "/" +
                companyId +
                "/crewMember/" +
                dat.id +
                "/" +
                dat.imageTimeStamp.seconds +
                "/"
            );
            try {
              const url = await storageRef.child("med").getDownloadURL();
              dat.image = url;
            } catch (exception) {}
          } catch (err) {}
        }
        return dat;
      })
    );

    const crewSchedules = await db
      .collection("inventories")
      .doc(inventoryId)
      .collection("crew_schedules")
      .where("jobID", "==", jobId)
      .get();

    const datacrewSchedules = await Promise.all(
      crewSchedules.docs.map(async (doc) => {
        let dat = doc.data();
        dat.id = doc.id;
        return dat;
      })
    );

    const addedCrewIds = datacrewSchedules.map((x) => x.crewMemberID);
    const addedCrewmembers = data.filter((x) => addedCrewIds.includes(x.id));
    const otherCrewmembers = data.filter((x) => !addedCrewIds.includes(x.id));

    this.setState({
      addedCrewmembers: addedCrewmembers,
      otherCrewmembers: otherCrewmembers,
      crewListLoading: false,
    });
  };

  loadEquipment = async (jobId, inventoryId, companyId) => {
    this.setState({ equipmentListLoading: true });
    const companyEquipment = await db
      .collection("inventories")
      .doc(inventoryId)
      .collection("equipment")
      .get();

    const data = await Promise.all(
      companyEquipment.docs.map(async (doc) => {
        let dat = doc.data();
        dat.id = doc.id;
        return dat;
      })
    );

    const reservations = await db
      .collection("inventories")
      .doc(inventoryId)
      .collection("reservations")
      .where("jobID", "==", jobId)
      .get();

    const dataReservations = await Promise.all(
      reservations.docs.map(async (doc) => {
        let dat = doc.data();
        dat.id = doc.id;
        return dat;
      })
    );

    const addedEquipmentIds = dataReservations.map((x) => x.equipmentID);
    const addedEquipment = data.filter((x) => addedEquipmentIds.includes(x.id));
    const otherEquipment = data.filter(
      (x) => !addedEquipmentIds.includes(x.id)
    );

    this.setState({
      addedEquipment: addedEquipment,
      otherEquipment: otherEquipment,
      equipmentListLoading: false,
    });
    this.loadEquipmentImages(addedEquipment, otherEquipment, companyId);
  };

  loadEquipmentImages = async (addedEquipment, otherEquipment, companyId) => {
    await Promise.all(
      addedEquipment.map(async (dat) => {
        if (dat.hasProfileImage && dat.imageTimeStamp) {
          try {
            const storageRef = storage.ref(
              "/" +
                companyId +
                "/equipment/" +
                dat.id +
                "/" +
                dat.imageTimeStamp.seconds +
                "/"
            );
            const url = await storageRef.child("med").getDownloadURL();

            const eq = [...this.state.addedEquipment];
            const index = eq.findIndex((x) => x.id === dat.id);
            eq[index] = { ...eq[index], image: url };

            this.setState({ addedEquipment: eq });
          } catch (err) {}
        }
      })
    );

    await Promise.all(
      otherEquipment.map(async (dat) => {
        if (dat.hasProfileImage && dat.imageTimeStamp) {
          try {
            const storageRef = storage.ref(
              "/" +
                companyId +
                "/equipment/" +
                dat.id +
                "/" +
                dat.imageTimeStamp.seconds +
                "/"
            );
            const url = await storageRef.child("med").getDownloadURL();

            const eq = [...this.state.otherEquipment];
            const index = eq.findIndex((x) => x.id === dat.id);
            eq[index] = { ...eq[index], image: url };

            this.setState({ otherEquipment: eq });
          } catch (err) {}
        }
      })
    );
  };

  loadCrews = async (jobId, inventoryId) => {
    const crews = await getCrews(inventoryId);
    const crewAppoitments = await getCrewAppointments(inventoryId);
    const jobCrewAppoitments = crewAppoitments.data.filter(
      (x) => x.jobID === jobId
    );

    const jobCrewAppoitmentsIds = jobCrewAppoitments.map((x) => x.crewID);
    const addedCrews = crews.data.filter((x) =>
      jobCrewAppoitmentsIds.includes(x.id)
    );
    const otherCrews = crews.data.filter(
      (x) => !jobCrewAppoitmentsIds.includes(x.id)
    );

    this.setState({ addedCrews: addedCrews, otherCrews: otherCrews });
  };

  openFolder = (folder) => {
    this.setState({
      images: folder.images,
      selectedImageFolder: folder,
      selectedImageIndex: 0,
      foldersPath: [...this.state.foldersPath, folder.id],
    });
  };

  toRootFolder = () => {
    this.setState({
      images: this.state.job.images,
      selectedImageFolder: null,
      selectedImageIndex: 0,
    });
  };

  goBack = () => {
    const newFolderPath = this.state.foldersPath.slice(0, -1);
    const currentFolder =
      newFolderPath.length > 0 ? newFolderPath[newFolderPath.length - 1] : null;
    const selectedFolder = this.state.jobPhotos.find(
      (folder) => folder.id == currentFolder
    );

    this.setState({
      images: [],
      selectedImageFolder: selectedFolder,
      selectedImageIndex: 0,
      foldersPath: newFolderPath,
    });
  };

  changeJobClient = async (clientId) => {
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    this.setState({ changingClient: true });

    await updateJob(inventoryId, id, { clientID: clientId });
    await this.loadData();
    this.setState({ changingClient: false });
  };

  capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  getDates = () => {
    return (
      moment(this.state.job?.startDate?.toDate()).format("MM/DD/YY") +
      " - " +
      moment(this.state.job?.endDate?.toDate()).format("MM/DD/YY")
    );
  };

  loadBores = async (jobId, inventoryId) => {
    this.setState({ boresLoading: true });
    const data = await getBores(inventoryId, jobId);
    this.setState({ bores: data.data, boresLoading: false });
  };

  touchJobDetailsField = (field) => {
    let jobDetailsForm = { ...this.state.jobDetailsForm };
    let updatedField = jobDetailsForm.fields.find((x) => x.name === field.name);
    if (!updatedField) return;

    if (updatedField.name === "dates") {
      updatedField.isTouched =
        field.startDate.value !== field.defaultStartDate ||
        field.endDate.value !== field.defaultEndDate;
    } else {
      updatedField.isTouched = field.value !== field.defaultValue;
    }

    this.setState({ jobDetailsForm: jobDetailsForm });
  };

  setTabActive = (tab) => {
    this.setState({ tabActive: tab });
  };

  touchClientDetailsField = (field) => {
    let clientDetailsForm = { ...this.state.clientDetailsForm };
    let updatedField = clientDetailsForm.fields.find(
      (x) => x.name === field.name
    );
    if (!updatedField) return;

    updatedField.isTouched = field.value !== field.defaultValue;
    this.setState({ clientDetailsForm: clientDetailsForm });
  };

  handleJobDetailsChange = (event, obj) => {
    let jobDetailsForm = { ...this.state.jobDetailsForm };

    if (event === "dates") {
      let field = jobDetailsForm.fields.find((x) => x.name === "dates");
      field.startDate.value = moment(obj.startDate.toDate());
      field.endDate.value = moment(obj.endDate.toDate());

      this.setState({ jobDetailsForm: jobDetailsForm });
      return;
    } else {
      let field = jobDetailsForm.fields.find(
        (x) => x.name === event.target.name
      );

      if (!field) return;

      if (field.name === "dates") {
        field.startDate.value = moment(obj.startDate.toDate());
        field.endDate.value = moment(obj.endDate.toDate());

        if (
          field.startDate === field.defaultStartDate &&
          field.endDate === field.defaultEndDate
        ) {
          field.isTouched = false;
        }
      } else {
        field.value = event.target.value;
        if (field.value === field.defaultValue) field.isTouched = false;
      }
    }

    this.validateJobDetails();

    jobDetailsForm.isValid = this.getGlobalValid(jobDetailsForm.fields);
    this.setState({ jobDetailsForm: jobDetailsForm });
  };

  handleClientDetailsChange = (event) => {
    let clientDetailsForm = { ...this.state.clientDetailsForm };

    let field = clientDetailsForm.fields.find(
      (x) => x.name === event.target.name
    );

    if (!field) return;

    if (field.subtype === "phone") {
      field.value = normalizeInput(event.target.value, field.value);
    } else {
      field.value = event.target.value;
    }
    if (field.value === field.defaultValue) field.isTouched = false;

    this.validateClientDetails();

    clientDetailsForm.isValid = this.getGlobalValid(clientDetailsForm.fields);
    this.setState({ clientDetailsForm: clientDetailsForm });
  };

  validateJobDetails = () => {
    this.state.jobDetailsForm.fields.forEach((field) => {
      if (field.name === "dates") {
        field.isValid = true;
      } else {
        validateField(field);
      }
    });
  };

  validateClientDetails = () => {
    this.state.clientDetailsForm.fields.forEach((field) => {
      validateField(field);
    });
  };

  getGlobalValid = (fields) => {
    let isValid = true;
    fields.forEach((element) => {
      if (
        element.name !== "dates" &&
        ((element.required && !element.value) || !element.isValid)
      )
        isValid = false;
    });

    return isValid;
  };

  uploadPhotos = async (acceptedFiles) => {
    this.setState({ photosUploading: true });
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const companyId = this.context.currentUser.userProfile.companyID;
    const inventoryId = currentUser.company.inventoryID;

    let job = await getJob(inventoryId, id);
    job = job.data;

    if (!job.photos) {
      job.photos = [];
    }
    let index = job.photos.length;

    acceptedFiles.forEach((x, i) => {
      let date = new Date();
      date.setSeconds(date.getSeconds() + 100 * i);
      job.photos.push(date);
    });

    await updateJob(inventoryId, id, { photos: job.photos });
    job = await getJob(inventoryId, id);

    await Promise.all(
      acceptedFiles.map(async (x) => {
        const timestamp = job.data.photos[index];
        index++;
        await uploadImage(companyId, "job", id, timestamp, x);
      })
    );

    await this.loadData();
    let newSelected = this.state.selectedImageIndex
      ? this.state.selectedImageIndex
      : 0;

    this.setState({ photosUploading: false, selectedImageIndex: newSelected });
    this.loadJobPhotos(id, inventoryId, companyId)
  };

  createZip = async () => {
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const companyId = this.context.currentUser.userProfile.companyID;
    const inventoryId = currentUser.company.inventoryID;

    this.setState({ preparingZipData: true });

    this.checkIfZipExists();

    let createZipFunc = functions.httpsCallable("createZip");
    createZipFunc({ jobID: id })
      .then((result) => {
        setTimeout(() => {
          this.setState({ preparingZipData: false });
          updateJob(inventoryId, id, { lastDataPackageCreated: new Date() });
          this.loadData();
          this.checkIfZipExists();
        }, 3000);
      })
      .catch((error) => {
        this.setState({ preparingZipData: false });
        showToast("danger", "Preparing job data failed please try again");
      });
  };

  checkZipCreatedDate = async () => {
    if (
      !this.state.job.lastDataPackageCreated ||
      moment(this.state.job.lastDataPackageCreated.toDate()).isBefore(
        moment("2021-11-13")
      )
    ) {
      this.createZip();
    }
  };

  checkIfZipExists = async () => {
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const companyId = this.context.currentUser.userProfile.companyID;
    const inventoryId = currentUser.company.inventoryID;

    const storageRef = storage.ref(companyId + "/jobpackages/");

    storageRef
      .child(this.state.job.id + "-v1")
      .getDownloadURL()
      .then(
        () => {
          this.setState({ zipChecked: true, zipExists: true });
          // clearInterval(this.interval);
        },
        () => {
          this.setState({ zipChecked: true, zipExists: false });
        }
      );
  };

  removePhotoFromState = (photoId) => {
    let photos = [...this.state.jobPhotos];
    if (this.state.selectedImageFolder) {
      const folder = photos.find(
        (x) => x.id === this.state.selectedImageFolder.id
      );
      folder.images = folder.images.filter((x) => x.id !== photoId);
    } else {
      photos = photos.filter((x) => x.id != photoId);
    }
    this.setState({ jobPhotos: photos });
  };

  removeOldPhotoFromState = (index) => {
    let photos = [...this.state.jobPhotos];
    photos = photos.filter((x) => x.oldPhotoIndex != index);

    this.setState({ jobPhotos: photos });
  };

  removePhoto = (index) => {
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = this.context.currentUser.userProfile.companyID;
    let job = { ...this.state.job };

    job.photos.splice(index, 1);

    db.collection("inventories")
      .doc(inventoryId)
      .collection("jobs")
      .doc(id)
      .update({
        photos: job.photos,
      });

    this.loadJobPhotos(id, inventoryId, companyId);
    this.removeOldPhotoFromState(index);
  };

  submitJobDetails = async (event) => {
    event?.preventDefault();
    let jobDetailsForm = { ...this.state.jobDetailsForm };
    jobDetailsForm.loading = true;
    this.setState({ jobDetailsForm: jobDetailsForm });

    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const jobResult = await getJob(inventoryId, id);

    if (jobResult.status == "success") {
      let job = {...jobResult.data};

      jobDetailsForm.fields.forEach((x) => {
        if (x.name === "dates") {
          job.startDate = toFirebaseDate(x.startDate.value.toDate());
          job.endDate = toFirebaseDate(x.endDate.value.toDate());
        } else {
          job[x.name] = x.value;
        }
      });

      await updateJob(inventoryId, id, job);

      //TODO implement updating reservation dates for crew, crewmembers and equipment
      this.updateReservations(inventoryId, jobResult.data, job)
    }

    jobDetailsForm.loading = false;
    this.setState({ jobDetailsForm: jobDetailsForm });
    await this.loadData();

    if (this.state.job.status === "completed") {
      this.createZip();
    }
  };

  setSelectedImage = (index) => {
    this.setState({ selectedImageIndex: index, imageViewerActive: true });
  };

  updateReservations = async (inventoryId, oldJobData, newJobData) =>{
    if (
      !newJobData.startDate.isEqual(oldJobData.startDate) ||
      !newJobData.endDate.isEqual(oldJobData.endDate)
    ){
      updateJobReservations(inventoryId, newJobData)
    }
  }

  saveLocation = async (lat, lng, location) => {
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    let job = (await getJob(inventoryId, id))?.data

    job.locationLat = lat;
    job.locationLong = lng;
    job.locationName = location;

    await updateJob(inventoryId, id, job)

    this.loadData();
  };

  submitClientDetails = async (event) => {
    event.preventDefault();
    let clientDetailsForm = { ...this.state.clientDetailsForm };
    clientDetailsForm.loading = true;
    this.setState({ clientDetailsForm: clientDetailsForm });

    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    let client = await db
      .collection("inventories")
      .doc(inventoryId)
      .collection("clients")
      .doc(this.state.job.clientID)
      .get();
    client = client.data();

    this.state.clientDetailsForm.fields.forEach((x) => {
      client[x.name] = x.value;
    });

    await db
      .collection("inventories")
      .doc(inventoryId)
      .collection("clients")
      .doc(this.state.job.clientID)
      .update(client);

    this.loadData();
  };

  loadCheckLists = async (job, inventoryId) => {
    this.setState({ checklistsLoading: true });
    await this.loadCheckOutLists(job, inventoryId);
    await this.loadCheckInLists(job, inventoryId);
    this.setState({ checklistsLoading: false });
  };

  loadCheckOutLists = async (job, inventoryId) => {
    let reservations = await getReservationsForAJob(inventoryId, job.id);
    let materials = await getCustomChecklists(inventoryId, job.id);

    let checkoutList = [];

    if (materials.data.length > 0) {
      let obj = {
        date: moment(job.startDate.toDate()).format("MM/DD/YYYY"),
        reservations: materials.data,
      };

      checkoutList.push(obj);
    }

    reservations.data.forEach((reservation) => {
      const date = moment(reservation.startDate.toDate()).format("MM/DD/YYYY");

      let obj = checkoutList.find((x) => x.date === date);

      if (obj) {
        obj.reservations.push(reservation);
      } else {
        obj = {
          date: date,
          reservations: [reservation],
        };

        checkoutList.push(obj);
      }
    });

    checkoutList.forEach((x) => {
      const checkOutNumber = x.reservations.filter(
        (x) => x.checkOutDate
      ).length;
      const percentage =
        checkOutNumber === 0
          ? 0
          : (100 * checkOutNumber) / x.reservations.length;
      x.percentage = percentage.toFixed(2);
      x.checkedOutNumber = checkOutNumber;
    });

    this.setState({ checkoutList: checkoutList });
  };

  loadCheckInLists = async (job, inventoryId) => {
    let reservations = await getReservationsForAJob(inventoryId, job.id);
    let materials = await getCustomChecklists(inventoryId, job.id);

    let checkinList = [];

    if (materials.data.length > 0) {
      let obj = {
        date: moment(job.endDate.toDate()).format("MM/DD/YYYY"),
        reservations: materials.data,
      };

      checkinList.push(obj);
    }

    reservations.data.forEach((reservation) => {
      const date = moment(reservation.endDate.toDate()).format("MM/DD/YYYY");

      let obj = checkinList.find((x) => x.date === date);

      if (obj) {
        obj.reservations.push(reservation);
      } else {
        obj = {
          date: date,
          reservations: [reservation],
        };

        checkinList.push(obj);
      }
    });

    checkinList.forEach((x) => {
      const checkInNumber = x.reservations.filter((x) => x.checkInDate).length;
      const percentage =
        checkInNumber === 0 ? 0 : (100 * checkInNumber) / x.reservations.length;
      x.percentage = percentage.toFixed(2);
      x.checkedInNumber = checkInNumber;
    });

    this.setState({ checkinList: checkinList });
  };

  enableConfirmModal = () => {
    this.setState({
      deleteJobModalActive: true,
    });
  };

  disableConfirmModal = () => {
    this.setState({
      deleteJobModalActive: false,
    });
  };

  enableImageViewer = () => {
    this.setState({ imageViewerActive: true });
  };

  disableImageViewer = () => {
    this.setState({
      imageViewerActive: false,
    });
  };

  deleteJob = async () => {
    const id = this.state.job.id;

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    let reservations = await getReservationsForAJob(inventoryId, id);
    let schedules = await getCrewSchedulesForJob(inventoryId, id);

    reservations.data.forEach((reservation) => {
      db.collection("inventories")
        .doc(inventoryId)
        .collection("reservations")
        .doc(reservation.id)
        .delete();
    });

    schedules.data.forEach((schedule) => {
      db.collection("inventories")
        .doc(inventoryId)
        .collection("crew_schedules")
        .doc(schedule.id)
        .delete();
    });

    db.collection("inventories")
      .doc(inventoryId)
      .collection("jobs")
      .doc(id)
      .delete()
      .then(() => {
        this.props.history.push("/jobs");
      })
      .catch((error) => {});

    this.setState({ deleteJobModalActive: false });
  };

  setTutorialBores = (active) => {
    const setTutorialStep = this.context.setTutorialStep;
    setTutorialStep("bores");

    window.$('[id="bores"]').tooltip({ trigger: "manual" });
    window.$('[id="job-bores-tab"]').tooltip("hide");

    window.$('[id="job-bores-tab"]').tooltip("show");
  };

  showTutorailModal = () => {
    if (this.isTutorial && ["bores"].includes(this.tutorialStep)) {
      window.$('[id="job-bores-tab"]').tooltip("hide");
      const setIsTutorial = this.context.setIsTutorial;
      setIsTutorial(false);

      setTimeout(() => {
        this.setState({ showTutorialModal: true });
      }, 1000);
    }
  };

  render() {
    if (this.state.job) {
      return (
        <>
          <div className="d-flex flex-column-fluid">
            <div className="container">
              <div className="card card-custom mb-5">
                <div className="card-header card-header-tabs-line nav-tabs-line-3x">
                  <div className="card-toolbar w-100">
                    <ul className="nav nav-tabs nav-bold nav-tabs-line nav-tabs-line-3x">
                      <li className="nav-item job-nav-item mr-3">
                        <Link
                          to={`${this.props.match.url}`}
                          className={
                            this.state.tabActive === "details"
                              ? "nav-link active job-nav-a"
                              : "nav-link job-nav-a"
                          }
                          data-toggle="tab"
                        >
                          <span className="nav-icon">
                            <span className="svg-icon">
                              <JobsIos />
                            </span>
                          </span>
                          <span className="nav-text font-size-lg">Job</span>
                        </Link>
                      </li>
                      <li className="nav-item job-nav-item mr-3">
                        <Link
                          to={`${this.props.match.url}/documents`}
                          className={
                            this.state.tabActive === "documents"
                              ? "nav-link job-nav-a active"
                              : "nav-link job-nav-a"
                          }
                          data-toggle="tab"
                        >
                          <span className="nav-icon">
                            <span className="svg-icon">
                              <Folder />
                            </span>
                          </span>
                          <span className="nav-text font-size-lg">
                            Job Documents
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item job-nav-item mr-3">
                        <Link
                          to={`${this.props.match.url}/taskmanagement`}
                          className={
                            this.state.tabActive === "taskmanagement"
                              ? "nav-link job-nav-a active"
                              : "nav-link job-nav-a"
                          }
                          data-toggle="tab"
                        >
                          <span className="nav-icon">
                            <span className="svg-icon">
                              <JobBores />
                            </span>
                          </span>
                          <span className="nav-text font-size-lg">
                            Task Management
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item job-nav-item mr-3">
                        <Link
                          to={`${this.props.match.url}/locatetickets`}
                          className={
                            this.state.tabActive === "locatetickets"
                              ? "nav-link job-nav-a active"
                              : "nav-link job-nav-a"
                          }
                          data-toggle="tab"
                        >
                          <span className="nav-icon">
                            <span className="svg-icon">
                              <LocateTicket />
                            </span>
                          </span>
                          <span className="nav-text font-size-lg">
                            Locate Tickets
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item job-nav-item mr-3">
                        <Link
                          id="job-bores-tab"
                          onClick={this.showTutorailModal}
                          data-toggle="tooltip"
                          title="Schedule and log all the separate bores for the job here. See their progress in real-time. Export the log as needed."
                          to={`${this.props.match.url}/bores`}
                          className={
                            this.state.tabActive === "bores"
                              ? "nav-link job-nav-a active"
                              : "nav-link job-nav-a"
                          }
                        >
                          <span className="nav-icon">
                            <span className="svg-icon">
                              <JobBores />
                            </span>
                          </span>
                          <span className="nav-text font-size-lg">
                            Job Bores
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item job-nav-item mr-3">
                        <Link
                          to={`${this.props.match.url}/crews`}
                          className={
                            this.state.tabActive === "crews"
                              ? "nav-link job-nav-a active"
                              : "nav-link job-nav-a"
                          }
                          data-toggle="tab"
                        >
                          <span className="nav-icon">
                            <span className="svg-icon">
                              <CrewsIos />
                            </span>
                          </span>
                          <span className="nav-text font-size-lg">Crews</span>
                        </Link>
                      </li>
                      <li className="nav-item job-nav-item mr-3">
                        <Link
                          to={`${this.props.match.url}/equipment`}
                          className={
                            this.state.tabActive === "equipment"
                              ? "nav-link job-nav-a active"
                              : "nav-link job-nav-a"
                          }
                          data-toggle="tab"
                        >
                          <span className="nav-icon">
                            <span className="svg-icon">
                              <EquipmentIos />
                            </span>
                          </span>
                          <span className="nav-text font-size-lg">
                            Equipment
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item job-nav-item mr-3">
                        <Link
                          to={`${this.props.match.url}/checklist`}
                          className={
                            this.state.tabActive === "checklist"
                              ? "nav-link job-nav-a active"
                              : "nav-link job-nav-a"
                          }
                          data-toggle="tab"
                        >
                          <span className="nav-icon">
                            <span className="svg-icon">
                              <CheckList />
                            </span>
                          </span>
                          <span className="nav-text font-size-lg">
                            Checklist
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item job-nav-item mr-3">
                        <Link
                          to={`${this.props.match.url}/crewmembers`}
                          className={
                            this.state.tabActive === "crewmembers"
                              ? "nav-link job-nav-a active"
                              : "nav-link job-nav-a"
                          }
                          data-toggle="tab"
                        >
                          <span className="nav-icon">
                            <span className="svg-icon">
                              <CrewmemberIos />
                            </span>
                          </span>
                          <span className="nav-text font-size-lg">
                            Crewmembers
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane show active"
                id="kt_user_edit_tab_1"
                role="tabpanel"
              >
                <Route
                  path={`${this.props.match.path}`}
                  exact={true}
                  render={(props) => (
                    <JobDetailsInfo
                      job={this.state.job}
                      images={this.state.images}
                      openFolder={this.openFolder}
                      selectedImageFolder={this.state.selectedImageFolder}
                      touchJobDetailsField={this.touchJobDetailsField}
                      jobDetailsForm={this.state.jobDetailsForm}
                      handleJobDetailsChange={this.handleJobDetailsChange}
                      submitJobDetails={this.submitJobDetails}
                      resetJobDetailsForm={this.resetJobDetailsForm}
                      touchClientDetailsField={this.touchClientDetailsField}
                      clientDetailsForm={this.state.clientDetailsForm}
                      handleClientDetailsChange={this.handleClientDetailsChange}
                      submitClientDetails={this.submitClientDetails}
                      resetClientDetailsForm={this.resetClientDetailsForm}
                      saveLocation={this.saveLocation}
                      uploadPhotos={this.uploadPhotos}
                      removePhoto={this.removePhoto}
                      photosUploading={this.state.photosUploading}
                      setSelectedImage={this.setSelectedImage}
                      selectedImageIndex={this.state.selectedImageIndex}
                      canEdit={this.canEdit}
                      user={this.context.currentUser}
                      setTabActive={this.setTabActive}
                      clients={this.state.clients}
                      enableConfirmModal={this.enableConfirmModal}
                      disableConfirmModal={this.disableConfirmModal}
                      deleteJobModalActive={this.state.deleteJobModalActive}
                      deleteJob={this.deleteJob}
                      changeJobClient={this.changeJobClient}
                      changingClient={this.state.changingClient}
                      imageViewerActive={this.state.imageViewerActive}
                      enableImageViewer={this.enableImageViewer}
                      disableImageViewer={this.disableImageViewer}
                      boreImages={this.state.boreImages}
                      toRootFolder={this.goBack}
                      loadJobPhotos={this.loadJobPhotos}
                      viewerImages={this.state.viewerImages}
                      jobPhotos={this.state.jobPhotos}
                      removePhotoFromState={this.removePhotoFromState}
                      zipChecked={this.state.zipChecked}
                      preparingZipData={this.state.preparingZipData}
                      zipExists={this.state.zipExists}
                      createZip={this.createZip}
                      checkIfZipExists={this.checkIfZipExists}
                      updateJobClientContacts={this.updateJobClientContacts}
                      setTutorialBores={this.setTutorialBores}
                      refreshData={this.loadData}
                      {...props}
                    />
                  )}
                />
              </div>
              <div
                className="tab-pane show active"
                id="kt_user_edit_tab_2"
                role="tabpanel"
              >
                <Route
                  path={`${this.props.match.path}/crewmembers`}
                  exact={true}
                  render={(props) => (
                    <JobCrew
                      setTabActive={this.setTabActive}
                      otherCrewmembers={this.state.otherCrewmembers}
                      addedCrewmembers={this.state.addedCrewmembers}
                      crewListLoading={this.state.crewListLoading}
                      job={this.state.job}
                      {...props}
                    />
                  )}
                />
              </div>
              <div
                className="tab-pane show active"
                id="kt_user_edit_tab_3"
                role="tabpanel"
              >
                <Route
                  path={`${this.props.match.path}/equipment`}
                  exact={true}
                  render={(props) => (
                    <JobEquipment
                      setTabActive={this.setTabActive}
                      otherEquipment={this.state.otherEquipment}
                      addedEquipment={this.state.addedEquipment}
                      equipmentListLoading={this.state.equipmentListLoading}
                      job={this.state.job}
                      {...props}
                    />
                  )}
                />
              </div>
              <div
                className="tab-pane show active"
                id="kt_user_edit_tab_4"
                role="tabpanel"
              >
                <Route
                  path={`${this.props.match.path}/checklist`}
                  exact={true}
                  render={(props) => (
                    <JobChecklist
                      setTabActive={this.setTabActive}
                      checkoutList={this.state.checkoutList}
                      checkinList={this.state.checkinList}
                      job={this.state.job}
                      {...props}
                      checklistsLoading={this.state.checklistsLoading}
                    />
                  )}
                />
              </div>
              <div
                className="tab-pane show active"
                id="kt_user_edit_tab_5"
                role="tabpanel"
              >
                <Route
                  path={`${this.props.match.path}/bores`}
                  exact={true}
                  render={(props) => (
                    <Bores
                      setTabActive={this.setTabActive}
                      job={this.state.job}
                      bores={this.state.bores}
                      reloadBores={this.loadBores}
                      boresLoading={this.state.boresLoading}
                      addedCrews={this.state.addedCrews}
                      {...props}
                    />
                  )}
                />
              </div>
              <div
                className="tab-pane show active"
                id="kt_user_edit_tab_6"
                role="tabpanel"
              >
                <Route
                  path={`${this.props.match.path}/documents`}
                  exact={true}
                  render={(props) => (
                    <JobDocuments
                      setTabActive={this.setTabActive}
                      job={this.state.job}
                      documents={this.state.documents}
                      inventoryId={this.company.inventoryID}
                      companyId={this.user.companyID}
                      loadDocuments={this.loadDocuments}
                      {...props}
                    />
                  )}
                />
              </div>
              <div
                className="tab-pane show active"
                id="kt_user_edit_tab_7"
                role="tabpanel"
              >
                <Route
                  path={`${this.props.match.path}/crews`}
                  exact={true}
                  render={(props) => (
                    <JobCrews
                      setTabActive={this.setTabActive}
                      job={this.state.job}
                      companyId={this.user.companyID}
                      addedCrews={this.state.addedCrews}
                      otherCrews={this.state.otherCrews}
                      {...props}
                    />
                  )}
                />
                <div
                  className="tab-pane show active"
                  id="kt_user_edit_tab_4"
                  role="tabpanel"
                >
                  <Route
                    path={`${this.props.match.path}/locatetickets`}
                    exact={true}
                    render={(props) => {
                      return (
                        <LocateTicketComponent
                          job={this.state.job}
                          setTabActive={this.setTabActive}
                        />
                      );
                    }}
                  />
                </div>
                <div
                  className="tab-pane show active"
                  id="kt_user_edit_tab_4"
                  role="tabpanel"
                >
                  <Route
                    path={`${this.props.match.path}/taskmanagement`}
                    exact={true}
                    render={(props) => (
                      <TaskManagement
                        setTabActive={this.setTabActive}
                        job={this.state.job}
                        companyId={this.user.companyID}
                        addedCrews={this.state.addedCrews}
                        otherCrews={this.state.otherCrews}
                        {...props}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <InfoModal
            show={this.state.showTutorialModal}
            title=""
            id="welcome-message"
            body="We're here for you. Get more help from our tutorial videos here. Or contact us for a personal training session at support@equappment.com."
            yesButton={{
              title: "OK",
              onClick: () => {
                this.setState({ showTutorailModal: false });
              },
            }}
          />
        </>
      );
    } else {
      return <Loader />;
    }
  }
}

export default JobDetails;
