import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./Carousel.css";
import InputTextModal from "../Modals/InputTextModal";

export default class MultipleItems extends Component {
  state = {
    errorImages: [],
    selectedFileIndex: -1,
    showEditNameModal: false,
  };

  render() {
    const settings = {
      className: "center",
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: this.props.slidesToShow,
      arrows: true,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 1370,
          settings: {
            slidesToShow: this.props.laptop,
            dots: false,
            infinite: false,
            speed: 500,
            arrows: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: this.props.tablet,
            dots: false,
            infinite: false,
            speed: 500,
            arrows: true,
          },
        },
        {
          breakpoint: 375,
          settings: {
            slidesToShow: this.props.mobile,
            dots: false,
            infinite: false,
            speed: 500,
            arrows: true,
          },
        },
      ],
    };

    return (
      <React.Fragment>
        <Slider {...settings}>
          {this.props.selectedImageFolder && (
            <>
              <div
                style={{ cursor: "pointer", paddingLeft: "6px" }}
                onClick={() => this.props.toRootFolder()}
              >
                <i className="flaticon2-back" style={{ fontSize: "48px" }}></i>
              </div>
            </>
          )}

          {this.props.images?.map((imageObject, index) => {
            const image = imageObject?.image ?? imageObject;
            const title = imageObject.title;
            const name = imageObject.name;
            const createdAt = imageObject.createdAt
            const uploadedBy = imageObject.uploadedBy

            console.log(createdAt)
            console.log(uploadedBy)

            return (
              <React.Fragment key={"crsl-cmpnt" + index}>
                <div
                  style={{
                    opacity:
                      index === this.props.selectedImageIndex ? "1" : "0.6",
                    marginRight: "13px",
                    position: "relative",
                    cursor: "pointer",
                  }}
                  className="symbol symbol-75"
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={
                    this.props.setSelectedImage
                      ? () => {
                          this.props.setSelectedImage(index);
                        }
                      : null
                  }
                >
                  {this.state.errorImages.includes(image) ? (
                    <img
                      alt="Pic"
                      src={"/assets/media/equappment/ic_document.png"}
                    />
                  ) : (
                    <img
                      alt="Pic"
                      src={image}
                      onError={(error) => {
                        this.setState({
                          errorImages: [...this.state.errorImages, image],
                        });
                      }}
                    />
                  )}

                  {this.props.removePhoto &&
                    !this.props.selectedImageFolder && (
                      <span
                        style={{
                          position: "absolute",
                          top: "-2px",
                          right: "-6px",
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          this.props.removePhoto(index);
                        }}
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title="Remove image"
                      >
                        <i className="ki ki-bold-close icon-xs text-muted" />
                      </span>
                    )}

                  {this.props.editFile && (
                    <span
                      style={{
                        position: "absolute",
                        top: "-2px",
                        right: "26px",
                      }}
                      onClick={(event) => {
                        this.setState({
                          showEditNameModal: true,
                          selectedFileIndex: index,
                        });
                      }}
                      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="edit"
                      data-toggle="tooltip"
                      title="Edit image"
                    >
                      <i className="flaticon-edit-1 icon-xs" />{" "}
                      {/* Edit icon */}
                    </span>
                  )}
                  {name && <div className="mt-1 text-truncate max-w-90px">{name}</div>}
                  {createdAt && <div className="mt-1 text-truncate max-w-90px">{createdAt}</div>}
                  {uploadedBy && <div className="mt-1 text-truncate max-w-90px">{uploadedBy}</div>}
                  {title && <div className="mt-1 text-truncate max-w-90px">{title}</div>}
                </div>
              </React.Fragment>
            );
          })}

          {this.props.folders &&
            !this.props.selectedImageFolder &&
            this.props.folders.map((folder, index) => {
              return (
                <div
                  style={{ cursor: "pointer", paddingLeft: "8px" }}
                  key={"crsl-cmpnt" + index}
                  onClick={() => this.props.openFolder(folder)}
                >
                  <i
                    className="flaticon2-folder text-warning"
                    style={{ fontSize: "48px" }}
                  ></i>
                  <span style={{ display: "block", marginTop: "-14px" }}>
                    {folder.display}
                  </span>
                </div>
              );
            })}
        </Slider>
        <InputTextModal
          id={`multiple-items-edit-name-modal`}
          show={this.state.showEditNameModal}
          title="File name"
          body="Enter file name"
          yesButton={{
            title: "Yes",
            spinner: false,
            onClick: (value) => {
              if (this.props.onNameChanged)
                this.props.onNameChanged(value, this.state.selectedFileIndex);
              this.setState({
                showEditNameModal: false,
                selectedFileIndex: -1,
              });
            },
          }}
          noButton={{
            title: "Cancel",
            onClick: (value) => {
              this.setState({
                showEditNameModal: false,
                selectedFileIndex: -1,
              });
            },
          }}
        />
      </React.Fragment>
    );
  }
}
